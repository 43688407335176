<script setup>
import { NuxtLink } from '#components'
const props = defineProps({
  cta: Object,
  color: String,
  isCard: {
    type: Boolean,
    default: false,
  },
  isArticle: {
    type: Boolean,
    default: false,
  },
  button: {
    type: Boolean,
    default: false,
  },
  buttonLabel: String,
  ariaLabel: String,
})
const emit = defineEmits(['click'])
const { lenis } = useSmoothScroll()
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()
const btn = ref(null)
const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})
const lenisScrollTo = () => {
  const targetElement = document.querySelector(props.cta.href)
  const headerHeight = getComputedStyle(document.body).getPropertyValue(
    isMobile.value ? '--header-height-mobile' : '--header-height',
  )
  if (targetElement) {
    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY
    const offset = targetPosition - targetElement.offsetHeight - parseFloat(headerHeight)

    lenis.value.scrollTo(offset, {
      duration: 1,
      easing: (t) => {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
      },
    })
  }
}
const el = ref(null)

function getRandomIntBetween(min, max) {
  if (min > max) {
    ;[min, max] = [max, min]
  }

  return Math.floor(Math.random() * (max - min + 1)) + min
}

function separateText(element, mode = 'words') {
  if (!element) return
  let innerText = element.innerText
  element.innerHTML = ''

  let textContainer = document.createElement('div')
  textContainer.classList.add('block')

  let splitText
  if (mode === 'letters') {
    splitText = [...innerText] // Split into letters
  } else if (mode === 'words') {
    splitText = innerText.split(' ') // Split into words
  }

  splitText.forEach((part, index) => {
    let span = document.createElement('span')
    span.innerText = part.trim() === '' ? '\xa0' : part
    span.classList.add(mode === 'letters' ? 'letter' : 'word')
    span.style.setProperty('--index', index)
    // span.style.setProperty(
    //   "--index",
    //   getRandomIntBetween(0, splitText.length - 1),
    // );
    textContainer.appendChild(span)

    if (mode === 'words' && index < splitText.length - 1) {
      textContainer.appendChild(document.createTextNode('\xa0')) // Add space between words
    }
  })

  element.prepend(textContainer)
  element.prepend(textContainer.cloneNode(true))
}

onMounted(() => {
  if (props.button) {
    const element = btn.value?.querySelector('.cta-label')
    if (element) separateText(element)
  } else {
    if(props.cta?.type === 'contact') {
      const element = el.value?.querySelector('.cta-label')
      if (element) separateText(element)
    }
    const element = el.value?.$el?.querySelector('.cta-label')
    if (element) separateText(element)
  }
})

const openModal = () => {
  useEvent('modal:open', {
    data: null,
    type: 'contact',
  })
}
</script>

<template>
  <component
    v-if="cta && !button"
    ref="el"
    :is="cta.type === 'contact' ? 'button' : NuxtLink"
    :to="cta.href ?? null"
    :target="cta.target"
    :aria-label="cta.label ? cta.label : cta.type"
    :class="{
      'w-full': isCard,
      'w-max': !isCard,
      'flex flex-row gap-spacing-01-desktop': isArticle,
    }"
    class="cta block cursor-pointer items-center"
    data-cursor="empty"
    @click="
      cta.type === 'contact' ? openModal() : cta.href.startsWith('#') ? lenisScrollTo() : null
    "
  >
    <ui-font-text
      type="label"
      class="cta-label overflow-hidden"
      :class="{
        'mb-spacing-02': cta.label && !isArticle,
      }"
      v-if="cta.label"
    >
      {{ cta.label }}
    </ui-font-text>
    <NuxtIcon
      v-if="cta.isDownload"
      name="download"
      class="icon h-[12px] w-[12px] self-center"
    ></NuxtIcon>
    <span class="block h-[1px] w-full" :class="color" v-if="cta.label && !isArticle"></span>
  </component>
  <button
    @click="(e) => emit('click', e)"
    ref="btn"
    :aria-label="ariaLabel || 'button'"
    class="cta block cursor-pointer items-center"
    :class="{
      'w-full': isCard,
      'w-max': !isCard,
      'flex flex-row gap-spacing-01-desktop': isArticle,
    }"
    v-else-if="button && !isCard"
    data-cursor="empty"
  >
    <slot v-if="!buttonLabel" />
    <ui-font-text
      v-else
      type="label"
      class="cta-label overflow-hidden"
      :class="{
        'mb-spacing-02': buttonLabel && !isArticle,
      }"
    >
      {{ buttonLabel }}
    </ui-font-text>
    <span class="block h-[1px] w-full" :class="color" v-if="buttonLabel && !isArticle"></span>
  </button>
</template>
<style>
.icon svg {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.cta-label div {
  /* perspective: 1000px; */
  transform-style: preserve-3d;
  /* overflow: hidden; */
}

.cta-label div:nth-child(1) .word {
  will-change: transform;
  transform-style: preserve-3d;
  transition: 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* FOR WORDS */
  transition-delay: calc(0.1s * var(--index));
  /* FOR LETTERS */
  /* transition-delay: calc(0.025s * var(--index)); */
  /* transition-delay: calc(0.025s); */
  transform-origin: bottom;
  display: inline-block;
  transform: translate3d(0, -200%, 0);
}

.cta-label div:nth-child(2) .word {
  will-change: transform;
  transform-style: preserve-3d;
  transition: 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* FOR WORDS */
  transition-delay: calc(0.1s * var(--index));
  /* FOR LETTERS */
  /* transition-delay: calc(0.025s * var(--index)); */
  /* transition-delay: calc(0.025s); */
  transform-origin: top;
  display: inline-block;
  transform: translate3d(0, -100%, 0);
}

.cta:hover div:nth-child(1) .word {
  /* opacity: 0.5; */
  transform: translate3d(0, 0%, 0);
}

.cta:hover div:nth-child(2) .word {
  transform: translate3d(0, 200%, 0);
}
</style>

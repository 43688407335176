const duration = () => (window.innerWidth < 900 ? 600 : 1000)
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function onLeave(el, done, payload) {
    const { currentScroll } = useSmoothScroll()
    const scrollY = currentScroll.value
    el.style.overflow = "hidden"
    el.style.position = "fixed";
    el.style.top = scrollY * -1 + "px";
    el.style.width = "100vw";
    el.style.left = 0;

    el.style.transition = `opacity ${duration()}ms var(--ease-in-out), filter ${duration()}ms var(--ease-in-out)`

    el.style.filter = 'blur(10px)'
    el.style.opacity = 0.8

    setTimeout(() => {
        done()
    }, duration())
}
function onEnter(el, done, payload) {

    el.style.transform = `translateX(100vw)`
    el.style.overflow = 'hidden'
    el.firstElementChild.style.transform = `translateX(-50vw)`

    setTimeout(() => {
        el.style.position = 'relative'
        el.style.transition = `all ${duration()}ms cubic-bezier(.73,.06,.03,1)`
        el.firstElementChild.style.transition = `transform ${duration()}ms cubic-bezier(.73,.06,.03,1)`
        el.style.transform = `translateX(0vw)`
        el.firstElementChild.style.transform = `translateX(0vw)`

        setTimeout(() => {
            done()
            el.removeAttribute('style')
        }, duration())
    })
}

export default {
    onLeave,
    onEnter,
}
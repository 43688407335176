export default () => {
    const route = useRoute()
    const craftPreview =
        !!route.query.token || !!route.query['x-craft-live-preview']
    const localhost = useRequestURL().hostname === 'localhost'

    return {
        craftPreview,
        localhost,
    }
}
<script setup>
import { useReCaptcha } from 'vue-recaptcha-v3'

const props = defineProps({
  headline: String,
  text: String,
  consent: String,
})
const emit = defineEmits(['submitted'])
const mail = useMail()
// Form fields
const fullName = ref('')
const email = ref('')
const phoneNumber = ref('')
const company = ref('')
const message = ref('')

// 'success' | 'error' | 'loading'
const formStatus = ref(null)
const formErrorMessage = ref(null)

const recaptchaInstance = useReCaptcha()

const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  const token = await recaptchaInstance?.executeRecaptcha()

  return token
}

// Computed property to determine if the submit button should be disabled
const isDisabled = computed(() => {
  return !(fullName.value && email.value && phoneNumber.value && company.value && message.value)
})

const validEmail = (string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(string)
}

// Method to handle form submission
const submitForm = async (event) => {
  // Prevent default form submission if the form is invalid
  if (!event.target.checkValidity()) {
    event.preventDefault()
    return
  }
  if (!email.value || !validEmail(email.value)) {
    formStatus.value = 'error'
    formErrorMessage.value = 'Invalid email'
  }
  formStatus.value = 'loading'

  try {
    // Send the form to the API
    const token = await recaptcha()
    if (!token) return

    // Format the email content
    const emailBody = `
      New Contact Request

      Name: ${fullName.value}
      Email: ${email.value}
      Phone: ${phoneNumber.value}
      Company: ${company.value}

      Message:
      ${message.value}
  `

    // Send email via API
    mail.send({
      from: email.value,
      subject: `New Inquiry from ${fullName.value || company.value}`,
      text: emailBody,
    })

    // Emit the submitted event
    emit('submitted')

    formStatus.value = 'success'
  } catch (error) {
    formStatus.value = 'error'
    formErrorMessage.value = 'Something went wrong when sending the form'
  }
}
</script>

<template>
  <div class="contact-form flex h-full flex-col">
    <ui-font-text type="h2" class="mb-spacing-04 lg:mb-spacing-04-desktop">{{
      headline
    }}</ui-font-text>
    <ui-font-text type="body" class="mb-spacing-06 lg:mb-spacing-06-desktop">{{
      text
    }}</ui-font-text>

    <form
      class="flex h-full flex-col justify-between pb-[var(--grid-bleed)]"
      @submit="submitForm"
      onsubmit="return false"
    >
      <div class="inputs mb-spacing-06 flex flex-col gap-spacing-02-desktop">
        <div
          class="dual-inputs flex flex-col gap-spacing-02-desktop lg:flex-row lg:gap-[var(--grid-gap)]"
        >
          <div class="fullname relative lg:!w-[50%]">
            <input
              required
              type="text"
              v-model="fullName"
              placeholder="Full name"
              class="w-full whitespace-pre-line py-spacing-02 font-text text-body font-normal text-focus-dark lg:text-bodyDesktop xl:py-spacing-02-desktop ultra:text-bodyUltra"
            />
            <div class="absolute bottom-0 left-0 h-0 w-full border border-stroke-dark"></div>
          </div>
          <div class="tel relative lg:!w-[50%]">
            <input
              type="tel"
              v-model="phoneNumber"
              placeholder="Phone"
              class="w-full whitespace-pre-line py-spacing-02 font-text text-body font-normal text-focus-dark lg:text-bodyDesktop xl:py-spacing-02-desktop ultra:text-bodyUltra"
            />
            <div class="absolute bottom-0 left-0 h-0 w-full border border-stroke-dark"></div>
          </div>
        </div>
        <div class="email relative">
          <input
            type="email"
            v-model="email"
            required
            placeholder="Email"
            class="whitespace-pre-line py-spacing-02 font-text text-body font-normal text-focus-dark lg:text-bodyDesktop xl:py-spacing-02-desktop ultra:text-bodyUltra"
          />
          <div class="absolute bottom-0 left-0 h-0 w-full border border-stroke-dark"></div>
        </div>
        <div class="company relative">
          <input
            type="text"
            v-model="company"
            placeholder="Company"
            class="w-full whitespace-pre-line py-spacing-02 font-text text-body font-normal text-focus-dark lg:text-bodyDesktop xl:py-spacing-02-desktop ultra:text-bodyUltra"
          />
          <div class="absolute bottom-0 left-0 h-0 w-full border border-stroke-dark"></div>
        </div>
        <div class="message relative">
          <textarea
            v-model="message"
            placeholder="Message"
            required
            class="w-full whitespace-pre-line py-spacing-02 font-text text-body font-normal text-focus-dark lg:text-bodyDesktop xl:py-spacing-02-desktop ultra:text-bodyUltra"
          />
          <div class="absolute bottom-0 left-0 h-0 w-full border border-stroke-dark"></div>
        </div>
      </div>
      <div class="bottom inline-flex w-full items-center justify-between">
        <div class="error-message">
          <ui-font-text v-if="formStatus === 'error'" type="body">
            {{ formErrorMessage }}
          </ui-font-text>
        </div>
        <button
          aria-label="Submit"
          data-cursor="empty"
          :class="{
            'pointer-events-none cursor-not-allowed border-stroke-dark bg-transparent-background-light text-focus-dark':
              isDisabled || formStatus === 'error',
            'pointer-events-all cursor-pointer border-stroke-light bg-dark-green text-focus-light':
              !isDisabled,
          }"
          class="flex h-spacing-05-desktop w-full items-center rounded-corner-02 transition-all lg:ml-auto lg:w-[var(--cols-2)]"
          type="submit"
          :disabled="isDisabled || formStatus === 'error'"
        >
          <ui-font-text type="label" class="mx-auto flex flex-row gap-2.5">
            <div aria-label="Contact us" class="flex flex-row items-center gap-2.5">
              submit
              <NuxtIcon name="chevron-right" class="icon h-[10px] w-[10px] self-center"></NuxtIcon>
            </div>
          </ui-font-text>
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.icon:deep(svg) {
  fill: var(--text-theme);
  width: 100%;
  height: 100%;
}

.fullname,
.email {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: units(1.3);
}

input[type='text'],
input[type='tel'],
input[type='email'],
textarea {
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: text;
  z-index: 1;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--dark-green);
    opacity: 0.5;
  }
}
</style>

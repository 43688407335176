import { gsap } from 'gsap'

const delay = (ms = 0) => new Promise(r => setTimeout(r, ms))

const durationLeave = () => (window.innerWidth < 1200 ? 0.5 : .6)
const durationEnter = () => (window.innerWidth < 1200 ? 1 : .6)

let overlay


function createOverlay() {
  overlay = document.createElement('div')
  overlay.classList.add('overlay')

  Object.assign(overlay.style, {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 900,
    clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)'
  })
  document.body.append(overlay)
  for (let i = 0; i < 4; i++) {
    const curtain = document.createElement('div')
    curtain.classList.add('curtain')

    Object.assign(curtain.style, {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
      backgroundColor: i == 0 ? 'black' : i == 1 ? 'var(--dark-green)' : i == 2 ? 'var(--ivory)' : 'white',
    })
    overlay.append(curtain)
  }
  return overlay
}

function onBeforeLeave(el, payload) {
  document.querySelectorAll('.overlay').forEach(e => e.remove())
  createOverlay()
  el.style.zIndex = 2
}

async function onLeave(el, done, payload) {
  const duration = durationLeave()
  el.style.transformOrigin = 'top'
  el.style.transition = `all ${duration}s cubic-bezier(0.32, 0, 0.67, 0)`
  el.style.transform = 'translateY(10vh)'

  gsap.to(".curtain", {
    clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    ease: "power4.inOut",
    stagger: .07,
    duration: duration,
  });
  await delay(duration * 1000)
  done()
}

async function onEnter(el, done, payload) {
  el.style.transform = 'translateY(-10vh)'

  await delay(durationLeave() * 1000)

  const duration = durationEnter()
  // console.log('duration', duration)
  gsap.to('.curtain', {
    clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
    ease: "power4.inOut",
    stagger: -0.07,
    duration: duration,
  });
  gsap.to(".overlay", {
    clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
    ease: "power4.inOut",
    delay: duration / 2,
    duration: duration,
    onStart: () => {
      el.style.transition = `transform ${duration}s cubic-bezier(0.33, 1, 0.68, 1)`
      el.style.transform = 'translateY(0)'
    },
    onComplete: () => {
      done()
    }
  }
  )


}

function onAfterEnter(el, payload) {
  setTimeout(() => el?.removeAttribute('style'))
  overlay.remove()
}

export default {
  onBeforeLeave,
  onLeave,
  onEnter,
  onAfterEnter,
}
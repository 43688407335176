import { gsap } from 'gsap';

const createCursorFollower = (useIcons = false) => {
    const isTouchDevice = "ontouchstart" in window;
    if (isTouchDevice) return;
    const mouse = document.querySelector(".mouse");
    const mouseIcon = mouse.querySelector(".icon");
    const chevronIcon = mouse.querySelector('.chevron-icon');
    const plusIcon = mouse.querySelector('.plus-icon');
    const crossIcon = mouse.querySelector('.cross-icon');
    const downloadIcon = mouse.querySelector('.download-icon');
    const arrowDownIcon = mouse.querySelector('.arrow-down-icon');
    gsap.set(mouse, { xPercent: -50, yPercent: -50 });
    let xTo = gsap.quickTo(mouse, "x", { duration: 0.7, ease: "power3" }),
        yTo = gsap.quickTo(mouse, "y", { duration: 0.7, ease: "power3" });
    window.addEventListener("mousemove", (e) => {
        const { target } = e;
        const cursorType = target?.closest("[data-cursor]")?.getAttribute('data-cursor');

        xTo(e.clientX);
        yTo(e.clientY);
        gsap.to(mouse, {
            duration: 0.7,
            ease: "power2",
            opacity: cursorType ? 1 : 0.6,
            scale: cursorType ? 1 : .3,
            backgroundColor: cursorType ? "white" : "#e4d7d0",
        });
        if (!useIcons) return;
        gsap.set(mouseIcon, { display: 'none' });
        gsap.set(chevronIcon, { display: 'none' });
        gsap.set(plusIcon, { display: 'none' });
        gsap.set(crossIcon, { display: 'none' });
        gsap.set(downloadIcon, { display: 'none' });
        gsap.set(arrowDownIcon, { display: 'none' });

        if (cursorType === 'plus') {
            gsap.set(plusIcon, { display: 'block' });
        }
        if (cursorType === 'cross') {
            // gsap.set(crossIcon, { display: 'block' });
            gsap.to(plusIcon, { rotate: '180deg' }); // Rotate the plus icon to become a cross
        } else if (cursorType === 'chevron') {
            gsap.set(chevronIcon, { display: 'block' });
            gsap.to(plusIcon, { rotate: '0deg' }); // Reset rotation
        } else if (cursorType === 'download') {
            gsap.set(downloadIcon, { display: 'block' });
            gsap.to(plusIcon, { rotate: '0deg' }); // Reset rotation
        } else if (cursorType === 'arrow-down') {
            gsap.set(arrowDownIcon, { display: 'block' });
            gsap.to(plusIcon, { rotate: '0deg' }); // Reset rotation
        } else {
            // Default behavior when no specific cursorType is active
            gsap.to(plusIcon, { rotate: '0deg' }); // Reset rotation
        }
    });

    // gsap.to(
    //     mouseIcon,
    //     {
    //         duration: 0.7,
    //         ease: "power2",
    //         opacity: cursorType === 'plus' ? 1 : 0,
    //         rotate: cursorType === 'plus' ? "180deg" : "225deg",
    //     },
    //     "<",
    // );

    document.addEventListener("mouseleave", (e) => {
        gsap.to(mouse, {
            duration: 0.7,
            opacity: 0,
        });
    });
};

export default () => {
    return {
        createCursorFollower
    };
};

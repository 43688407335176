<script setup>
// TO OPEN modal FROM ANOTHER COMPONENT:
// const openmodal = async (path) => {
//   const { getArticle } = useWpApi();
//   const { data, error } = await getArticle(path);
//   useEvent("modal:open", {
//     data: data.value,
//      type:'article',
//   });
// };
// AND IF BUTTON CLICK:
// @click="openmodal(article.relative_link)"

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})

const isOpen = ref(false)
const modalData = ref(null)
const modalType = ref('')
const close = () => {
  isOpen.value = false
  setTimeout(() => {
    useEvent('modal:close')
  }, 400)
}

useListen('modal:open', (modal) => {
  // console.log("modal:open: ", modal);
  modalData.value = modal.data
  modalType.value = modal.type
  isOpen.value = true
})
useListen('modal:close', () => {
  isOpen.value = false
})
const { lenis } = useSmoothScroll()
watch(isOpen, (open) => {
  if (open) {
    isOpen.value = true
    modal.value.style.visibility = 'visible'
    if (!isMobile.value) lenis.value.stop()
  } else {
    if (!isMobile.value) lenis.value.start()
    setTimeout(() => {
      modal.value.style.visibility = 'hidden'
    }, 400)
  }
})

const modal = ref(null)

const { globalData: data } = useVandState()
const showSuccess = ref(false)
</script>
<template>
  <Teleport to="body">
    <div
      ref="modal"
      class="modal fixed right-[var(--grid-bleed)] top-[var(--grid-bleed)] z-[var(--z-index-modal)] h-[calc(100dvh-var(--grid-bleed)*2)] w-[calc(100vw-var(--grid-bleed)*2)] rounded-corner-02 px-[var(--grid-bleed)] lg:w-[var(--cols-6)] lg:rounded-corner-02-desktop"
      :class="{
        'is-open': isOpen,
        'is-contact': modalType === 'contact',
        'bg-ivory': !showSuccess,
        'is-success bg-dark-green': showSuccess,
      }"
      role="dialog"
      aria-label="Contact form modal"
    >
      <client-only>
        <button
          class="absolute right-[20px] top-[20px] h-spacing-02-desktop w-spacing-02-desktop cursor-pointer lg:right-[24px] lg:top-[24px]"
          @click="close"
          aria-label="Close modal button"
          data-cursor="empty"
        >
          <NuxtIcon class="icon cross-icon" name="cross-mouse" filled />
        </button>
      </client-only>
      <div
        class="contact-modal h-full overflow-y-auto pt-spacing-06"
        data-lenis-prevent
        v-if="modalType === 'contact'"
      >
        <transition name="fade">
          <component-element-contact-form
            v-if="!showSuccess"
            v-bind="data.contact"
            class="h-full"
            @submitted="showSuccess = true"
          />
          <component-element-success-form
            v-else
            v-bind="data.contact"
            class="h-full"
            @close="close"
          />
        </transition>
      </div>
    </div>
    <div
      class="backdrop invisble pointer-events-none fixed left-0 top-0 z-[var(--z-index-modal-bg)] h-screen w-screen bg-transparent-background-dark opacity-0 blur-0"
      :class="{
        'is-open': isOpen,
      }"
      @click="close"
    ></div>
  </Teleport>
</template>

<style>
.modal {
  --header-height: units(6);
  --transform-slide: calc(100% + var(--grid-bleed));
  --modal-width: 50vw;

  transform: translateX(var(--transform-slide));

  transition: transform 0.3s ease-in-out;
  z-index: 103;
}
.modal.is-open {
  --transform-slide: 0%;
  pointer-events: auto;
}
.backdrop {
  transition: all 0.4s ease-in-out;
}
.backdrop.is-open {
  visibility: visible;
  opacity: 1;
  backdrop-filter: blur(12.5px);
  pointer-events: all;
  cursor: pointer;
  z-index: 102;
}

.contact-modal .icon {
  display: block;
}
.contact-modal .icon svg {
  /* fill: var(--ivory); */
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.modal.is-success .cross-icon svg rect {
  fill: var(--ivory);
}
</style>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexFngg1VZzPRz8gaZzuDEeYBRhpo4WwrwZ_45MlEF1YYr_MMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as _91slug_93JeDKFbsOnMNteV44FbYQgOB8k3ES0ESAsAu_ib4an1EMeta } from "/opt/build/repo/frontend/pages/[slug].vue?macro=true";
import { default as _91slug_93pMA1Mkm9ur2Zwl3uzXRWDdbTxaT9YXHJXzqTAuXR_x4Meta } from "/opt/build/repo/frontend/pages/jobs/[slug].vue?macro=true";
import { default as fonts4qDwxENLzyRplTmdi_IxlXMqAApcCj4WcYldd_45H2EdgMeta } from "/opt/build/repo/frontend/pages/helpers/fonts.vue?macro=true";
import { default as _91slug_93B0A1ug_LJHXu2Ty_u3qfjELEe_a_45_fFtTIZHTfpr4DAMeta } from "/opt/build/repo/frontend/pages/articles/[slug].vue?macro=true";
import { default as dev_45gridMDOe6Vy7GTity6bvznKkh6GxqIzIZqdOJT6gRQnqCCQMeta } from "/opt/build/repo/frontend/pages/helpers/dev-grid.vue?macro=true";
import { default as api_45benchmarkm9ZVSuFG844gZe0FCR1RBvh9AEufIMTtbyfIWmdjymIMeta } from "/opt/build/repo/frontend/pages/helpers/api-benchmark.vue?macro=true";
import { default as component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4 } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/frontend/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/[slug].vue")
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/jobs/[slug].vue")
  },
  {
    name: "helpers-fonts",
    path: "/helpers/fonts",
    component: () => import("/opt/build/repo/frontend/pages/helpers/fonts.vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/opt/build/repo/frontend/pages/articles/[slug].vue")
  },
  {
    name: "helpers-dev-grid",
    path: "/helpers/dev-grid",
    component: () => import("/opt/build/repo/frontend/pages/helpers/dev-grid.vue")
  },
  {
    name: "helpers-api-benchmark",
    path: "/helpers/api-benchmark",
    component: () => import("/opt/build/repo/frontend/pages/helpers/api-benchmark.vue")
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/contact",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/whatwedo",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/whoweare",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/applied-biomimetic-is-now-vandstrom",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/updates",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/terms-of-use-policy",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/vandstrom-appoints-oliver-geschke",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  },
  {
    name: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4Meta?.name,
    path: "/vanstrom-presents-at-the-protein-society-in-boston",
    component: component_45stubwaedeZbCGyJ1COXIwaq_trgJq_pq6H2rXI1CLIloAH4
  }
]
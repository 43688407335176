<script setup>
const route = useRoute();
</script>

<template>
  <div class="dev-grid" v-if="route.query.grid !== undefined">
    <div class="col" v-for="idx in 12">{{ idx }}</div>
  </div>
</template>

<style lang="postcss">
.dev-grid {
  position: fixed;
  width: calc(100vw - var(--grid-bleed) * 2);
  height: 100vh;
  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: repeat(var(--grid-cols), 1fr);
  gap: var(--grid-gap);
  margin: 0 var(--grid-bleed);

  pointer-events: none;

  z-index: 1000;

  .col {
    --color: 10, 100%, 60%;

    color: hsla(var(--color), 1);
    background: hsla(var(--color), 0.1);
    border-left: 1px solid hsla(var(--color), 0.4);
    border-right: 1px solid hsla(var(--color), 0.4);

    display: flex;
    align-items: center;
    justify-content: center;

    @media (--until-md) {
      &:nth-child(n + 10) {
        display: none;
      }
    }
  }
}
</style>

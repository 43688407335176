<template>
  <component :is="elementType" :class="classString">
    <slot></slot>
    <span class="block h-[1px] w-full" :class="underlineColor" v-if="hasUnderline"></span>
  </component>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) =>
      [
        'h1',
        'h2',
        'h3',
        'h4',
        'h4-link',
        'h4Body',
        'body',
        'smallBody',
        'label',
        'labelMenu',
        'bigNumber',
        'error',
      ].includes(value),
  },
  hasUnderline: {
    type: Boolean,
    default: false,
  },
  underlineColor: {
    type: String,
    default: 'bg-ivory',
  },
})

// Determine the element type dynamically based on the prop
let elementType = 'h1' // Default to h1
if (props.type === 'h4-link') elementType = 'p'
if (props.type.startsWith('h')) {
  elementType = props.type
} else {
  elementType = 'p'
}

// Define Tailwind classes dynamically
const classes = {
  h1: 'font-display text-h1 lg:text-h1Tablet xl:text-h1Desktop ultra:text-h1Ultra font-normal break-words',
  h2: 'font-display text-h2 xl:text-h2Desktop font-normal ultra:text-h2Ultra  break-words',
  h3: 'font-display text-h3 xl:text-h3Desktop font-normal break-words ultra:text-h3Ultra',
  h4: 'font-display text-h4 xl:text-h4Desktop font-normal',
  h4Link: 'font-display text-h4 xl:text-h4Desktop font-normal',
  h4Body: 'font-text text-h4Body xl:text-h4BodyDesktop font-normal',
  body: 'font-text text-body  xl:text-bodyDesktop font-normal whitespace-pre-line ultra:text-bodyUltra',
  smallBody: 'font-text text-smallBody xl:text-smallBodyDesktop font-normal  whitespace-pre-line ',
  label:
    'font-brand text-label xl:text-labelDesktop ultra:text-labelUltra  font-medium uppercase h-[0.65rem] ultra:h-[0.75rem]',
  labelMenu: 'font-brand text-labelMenu font-medium  uppercase ',
  bigNumber: 'font-display text-bigNumber xl:text-bigNumberDesktop font-normal uppercase ',
  error: 'font-brand text-error xl:text-errorDesktop font-normal uppercase ',
}

// Assign Tailwind classes based on the element type
const classString = classes[elementType === 'p' ? props.type : elementType]
</script>

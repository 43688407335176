/**
 * calls the /server api with default values
 */
// const EXPIRATION_DURATION = 60 * 60 * 1000 // 1 hour
export default async (url, options = {}) => {
    const nuxtApp = useNuxtApp()
    const route = useRoute()
    // console.group('route.query.token', route.query.token)
    // first '/api' is to hit /server, second is to hit element-api
    const { data, error } = await useFetch('/api/api/' + url + '.json', {
        // transform(input) {
        //     return {
        //         ...input,
        //         fetchedAt: new Date()
        //     }
        // },
        query: {
            token: route.query.token,
            ...(options.query || {}),
        },
        //     getCachedData(key) {
        //         const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        //         // If data is not fetched yet
        //         if (!data) {
        //             // Fetch the first time
        //             // console.log('FIRST TIME FETCH')
        //             return
        //         }

        //         // Is the data too old?
        //         const expirationDate = new Date(data.fetchedAt)
        //         expirationDate.setTime(expirationDate.getTime() + EXPIRATION_DURATION)
        //         const isExpired = expirationDate.getTime() < Date.now()
        //         if (isExpired) {
        //             // console.log('EXPIRED FETCH')
        //             // Refetch the data
        //             return
        //         }

        //         // console.log('CACHED FETCH FETCH')
        //         return data
        //     },
    })
    if (error.value) {
        throw createError({ statusCode: 404, fatal: true })
    }

    // cached response has a different structure..
    return {
        data: data.value?.query ? ref(data.value.data) : data,
        error: ref(data.value?.error) || error,
    }
}
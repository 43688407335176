<script setup>
const error = useError()
const route = useRoute()

const errorCache = JSON.parse(JSON.stringify(error.value))

const globalDataEndpoints = ['global/footer', 'global/contact', 'menu/header']

const { setGlobalData } = useVandState()

await callOnce(async () => {
  try {
    // run in parallel to speed things up
    const responses = await Promise.allSettled(globalDataEndpoints.map((url) => useCmsFetch(url)))

    const globalData = responses.reduce((acc, cur, idx) => {
      if (cur.status !== 'fulfilled') return

      const key = camelize(globalDataEndpoints[idx].split('/').at(-1))
      return { ...acc, [key]: cur.value.data }
    }, {})

    setGlobalData(globalData)
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Could not get global data',
    })
  }
})

watch(
  () => route.fullPath,
  async () => {
    await clearError()
  },
)
console.log(useRequestURL())
const goHome = () => {
  window.location.href = '/'
}
const config = useRuntimeConfig().public
useHead({
  title: '404 | Vandstrom',
  htmlAttrs: { lang: 'en' },
  meta: [
    { property: 'og:title', content: '404' },
    { name: 'description', content: 'Error page' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    },
  ],
  link: [{ href: useRequestURL().host + '/404', rel: 'canonical' }],
})
</script>

<template>
  <main data-theme="black">
    <globals-modal />
    <globals-cursor />
    <!-- <client-only>
      <globals-header class="pl-bleed pr-bleed" />
    </client-only> -->
    <NuxtLayout class="bg-black text-ivory">
      <div class="page error-page">
        <ui-bleed class="flex h-screen items-center justify-center">
          <div
            class="error-page-content flex h-full flex-col items-center justify-center gap-[40px] text-center"
          >
            <div class="number text-center">
              <ui-font-text type="error">404</ui-font-text>
            </div>
            <div class="ml-auto mr-auto">
              <ui-call-to-action
                @click="goHome"
                :button="true"
                button-label="Back home"
                color="bg-ivory"
              />
            </div>
          </div>
        </ui-bleed>
      </div>
      <!-- <globals-footer class="relative pl-bleed pr-bleed" /> -->
    </NuxtLayout>
  </main>
</template>

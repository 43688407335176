import revive_payload_client_h1Xdj8dzgZmXKnhnag597_TITpkVDyY9sHIkqVAI2Gs from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Dd_kbuKn6XhxHEq8H3E4xBf3cyc21LqSg3i64RWQ_WU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_o1gfuJunSbAxHjs_v_ZRQ4PjZynWBF9Add7dr_fmonQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wyLPQpj2uebJPNwlqwA45dlCCK4r6rFVQk3Mk8FD1ZU from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_qnXiKkmo20LOrSeKcRVr66HdVbdk9v4KCAe1u8PO0BQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1fDHVgBtPFcebocKDAv__2tqiNnnSBzkhqapUI9E6Vk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9AqEPKzhG96CqVF_efsZTTaHyyU0SVPIltHSmJgHf0g from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qf_wyD3JlXeqruCWtKEhhMKwSz4HuymWDaNjLNxaEwc from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_sI_Xhs21DjaZjhO7mqVb8vi0LLktcm7Vnr1LpnIWgo0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_rollup@4.39.0_terser_zb32omtxuuguhj275ay3n63eam/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_DQY3F6hH3dcrqq6ceWe2mo2eFOg4zrRNaJdblxrT8PU from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_nuxt3_nx9Oxqx5DJF_zJGR5qMZoL4R4Pr5iVhv2t1pBAhzwU8 from "/opt/build/repo/node_modules/.pnpm/nuxt-mail@5.1.1_magicast@0.3.5/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import Vue3Lottie_client_z7wCDCIvaoJIre21XGv8RZLne21Yq9w4QSIt8zAbUPg from "/opt/build/repo/frontend/plugins/Vue3Lottie.client.ts";
import google_recaptcha_eF3Wtspwnk6XN8jtyw34lzrV_fdf90dIb_8KRSuShng from "/opt/build/repo/frontend/plugins/google-recaptcha.js";
export default [
  revive_payload_client_h1Xdj8dzgZmXKnhnag597_TITpkVDyY9sHIkqVAI2Gs,
  unhead_Dd_kbuKn6XhxHEq8H3E4xBf3cyc21LqSg3i64RWQ_WU,
  router_o1gfuJunSbAxHjs_v_ZRQ4PjZynWBF9Add7dr_fmonQ,
  _0_siteConfig_wyLPQpj2uebJPNwlqwA45dlCCK4r6rFVQk3Mk8FD1ZU,
  payload_client_qnXiKkmo20LOrSeKcRVr66HdVbdk9v4KCAe1u8PO0BQ,
  navigation_repaint_client_1fDHVgBtPFcebocKDAv__2tqiNnnSBzkhqapUI9E6Vk,
  check_outdated_build_client_9AqEPKzhG96CqVF_efsZTTaHyyU0SVPIltHSmJgHf0g,
  chunk_reload_client_qf_wyD3JlXeqruCWtKEhhMKwSz4HuymWDaNjLNxaEwc,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_sI_Xhs21DjaZjhO7mqVb8vi0LLktcm7Vnr1LpnIWgo0,
  plugin_DQY3F6hH3dcrqq6ceWe2mo2eFOg4zrRNaJdblxrT8PU,
  plugin_nuxt3_nx9Oxqx5DJF_zJGR5qMZoL4R4Pr5iVhv2t1pBAhzwU8,
  Vue3Lottie_client_z7wCDCIvaoJIre21XGv8RZLne21Yq9w4QSIt8zAbUPg,
  google_recaptcha_eF3Wtspwnk6XN8jtyw34lzrV_fdf90dIb_8KRSuShng
]
<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Vue3Lottie } from 'vue3-lottie'

gsap.registerPlugin(ScrollTrigger)
const hasPlayed = useCookie('intro-animation-has-played', {
  maxAge: 60 * 60 * 24,
})
const intro = ref(null)
const { introAnimationIsDone, introAnimationShowContent } = useVandState()
const { craftPreview, localhost } = useEnvironment()

const { lenis } = useSmoothScroll()
let introTl = gsap.timeline()
let curtainsTl = gsap.timeline({
  paused: true,
})

function createOverlay() {
  const overlay = document.createElement('div')
  overlay.classList.add('backgrounds')

  Object.assign(overlay.style, {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 900,
    clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
  })
  intro.value.append(overlay)
  for (let i = 0; i < 4; i++) {
    const curtain = document.createElement('div')
    curtain.classList.add('background')

    Object.assign(curtain.style, {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)',
      backgroundColor:
        i == 0 ? 'black' : i == 1 ? 'var(--dark-green)' : i == 2 ? 'white' : 'var(--ivory)',
    })
    overlay.append(curtain)
  }

  curtainsTl.to('.background', {
    clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    ease: 'power4.inOut',
    stagger: -0.07,
    duration: 1,
    onStart: () => {
      introAnimationShowContent.value = true
    },
    onComplete: () => {
      setTimeout(() => {
        introAnimationIsDone.value = true
        hasPlayed.value = true
        lenis.value.start()
      }, 100)
    },
  })
  curtainsTl.to('.backgrounds', {
    clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    ease: 'power4.inOut',
    delay: 1 / 2,
    duration: 1,
  })
  return overlay
}

onMounted(async () => {
  /**
   * don't run
   */
  if (
    craftPreview
    //  || localhost
  ) {
    introAnimationIsDone.value = true
    introAnimationShowContent.value = true
    hasPlayed.value = true
    return
  }

  if (process.client && !hasPlayed.value) {
    lenis.value.stop()

    createOverlay()
    setTimeout(() => {
      curtainsTl.play()
    }, 3500)
  } else {
    introAnimationShowContent.value = true
    introAnimationIsDone.value = true
  }
})
// 116 frame
</script>

<template>
  <div
    v-if="!hasPlayed && !introAnimationIsDone"
    ref="intro"
    class="intro-animation fixed left-0 top-0 z-[var(--z-index-intro-animation)] h-screen w-screen"
  >
    <ui-bleed
      class="z-[901] mt-[35vh] flex h-full w-full flex-col items-center gap-[60px] lg:mt-0 lg:flex-row lg:gap-[calc(var(--cols-2)+var(--grid-gap))]"
    >
      <div class="div"></div>

      <div
        class="animation lottie absolute top-16 w-[var(--cols-8)] lg:left-[var(--push-3)] lg:top-[calc(50%-30px)] lg:w-[var(--cols-4)]"
      >
        <client-only>
          <div class="logo w-full transition-all lg:w-full">
            <client-only>
              <Vue3Lottie
                ref="lottieAnimation"
                animation-link="/lottie/loader-opt.json"
                :loop="false"
            /></client-only>
          </div>
        </client-only>
      </div>
    </ui-bleed>
  </div>
</template>

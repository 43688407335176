const duration = () => (window.innerWidth < 900 ? 1000 : 800);
function onBeforeLeave(el, { scrollY }) {

}

function onLeave(el, done, payload) {
    setTimeout(() => {
        el.style.zIndex = 100;
        el.style.transition = `transform ${duration()}ms cubic-bezier(.73,.06,.03,1)`;
        el.firstElementChild.style.transition = `transform ${duration()}ms cubic-bezier(.73,.06,.03,1)`;
        el.style.transform = `translateX(100vw)`;
        el.firstElementChild.style.transform = `translateX(50vw)`;
        setTimeout(() => {
            done();
            el.removeAttribute("style");
        }, duration());
    }, 100);
}

function onEnter(el, done, payload) {
    el.removeAttribute('style')
    el.style.filter = 'blur(10px)'
    el.style.opacity = 0.8

    setTimeout(() => {
        el.style.transition = `opacity ${duration()}ms var(--ease-in-out), filter ${duration()}ms var(--ease-in-out)`

        el.style.filter = 'blur(0px)'
        el.style.opacity = 1

    })
    setTimeout(() => {
        done();
    }, duration());
}

export default {
    onBeforeLeave,
    onLeave,
    onEnter,
};

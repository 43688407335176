<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import fallback from '~/transitions/fallback'
import overlayIn from '~/transitions/overlay-in'
import overlayOut from '~/transitions/overlay-out'
const config = useRuntimeConfig().public
const router = useRouter()
const route = useRoute()
// Simple server-side bot detection
const headers = useRequestHeaders()
const userAgent = headers['user-agent']?.toLowerCase() || ''
const isBot =
  userAgent.includes('bot') || userAgent.includes('lighthouse') || userAgent.includes('pagespeed')

const introAnimationHasPlayed = useCookie('intro-animation-has-played', {
  maxAge: 60 * 60 * 24,
})
// Skip animation for bots
if (isBot) {
  introAnimationHasPlayed.value = true
}
onMounted(() => {
  if (route.query.nointro) introAnimationHasPlayed.value = true
})
// name after '/' becomes key on the globalData object
// global/footer-menu -> globalData.value.footerMenu
const globalDataEndpoints = ['menu/footer', 'global/contact', 'global/infos', 'menu/header']

const { setGlobalData, pageIsTransitioning, introAnimationIsDone, introAnimationShowContent } =
  useVandState()

await callOnce(async () => {
  try {
    // run in parallel to speed things up
    const responses = await Promise.allSettled(globalDataEndpoints.map((url) => useCmsFetch(url)))

    const globalData = responses.reduce((acc, cur, idx) => {
      if (cur.status !== 'fulfilled') return

      const key = camelize(globalDataEndpoints[idx].split('/').at(-1))
      return { ...acc, [key]: cur.value.data }
    }, {})
    setGlobalData(globalData)
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Could not get global data',
    })
  }
})

const { seomatic, bodyScripts } = await useSeomatic()

useHead(seomatic)

useHead({
  meta: computed(() => [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    },
  ]),
  htmlAttrs: { lang: 'en' },
  link: computed(() => [
    { rel: 'preconnect', href: 'https://www.google.com' },
    { rel: 'preconnect', href: 'https://www.region1.google-analytics.com' },
    { rel: 'preconnect', href: 'https://www.gstatic.com' },
    { rel: 'preconnect', href: 'https://www.image.mux.com' },
    {
      rel: 'icon',
      href: '/dual-color-drop.svg',
    },
  ]),
  script: computed(() => [
    route.query['x-craft-live-preview'] ? { src: '/iframeResizer.min.js' } : {},
  ]),
})

const { currentScroll, lenis } = useSmoothScroll(true)

const app = ref(null)

const pageTransitions = usePageTransition({
  defaultTransition: fallback,
  transitions: { overlayIn, overlayOut },
  globalHooks: {
    onBeforeLeave(el) {
      pageIsTransitioning.value = true
      // place the old page in the place it was,
      // so it's safe to scroll to the top
      const scrollY = currentScroll.value
      if (router.transition?.name !== 'overlayOut') {
        el.style.top = scrollY * -1 + 'px'
        el.style.position = 'fixed'
        el.style.width = '100vw'
        el.style.left = 0
      }
    },
    onEnter(el) {
      ScrollTrigger.refresh()
    },
    onAfterEnter(el) {
      pageIsTransitioning.value = false
      el.removeAttribute('style')
    },
  },
})

watch(pageIsTransitioning, () => {
  pageIsTransitioning.value ? lenis.value.stop() : lenis.value.start()
})

if (!route.path.includes('article')) {
  document?.body?.classList.add('bg-dark-green')
}
</script>

<template>
  <main vaul-drawer-wrapper class="app min-h-[100vh]" ref="app">
    <globals-intro-animation v-if="!route.query.nointro" />
    <globals-modal />
    <globals-cursor />
    <div
      v-if="bodyScripts.length"
      v-for="bodyScript in bodyScripts"
      class="seomatic-body-script"
      :key="bodyScript"
      v-html="bodyScript"
    />

    <helpers-dev-grid />
    <div
      class="app transition-opacity duration-500"
      :class="{
        'opacity-0': !introAnimationIsDone && !introAnimationHasPlayed,
      }"
    >
      <NuxtLayout>
        <NuxtPage
          class="page flex flex-col"
          :class="{
            'overlay-page relative z-[calc(var(--z-index-header)+1)main]': route.meta.overlayPage,
            'intro-running': !introAnimationIsDone,
            'home-page': route.name === 'index',
          }"
          :transition="pageTransitions"
        />
      </NuxtLayout>
    </div>
  </main>
</template>

const useGlobalData = () => useState('global-data', () => null)
const useGlobalDropData = () => useState('global-drop-data', () => null)
const useIntroAnimationIsDone = () =>
    useState('intro-animation-is-done', () => false)
const useIntroAnimationShowContent = () =>
    useState('intro-animation-show-content', () => false)
const usePageIsTransitioning = () =>
    useState('page-is-transitioning', () => false)

export default () => {
    const globalData = useGlobalData()
    const globalDropData = useGlobalDropData()

    function setGlobalData(data) {
        if (data) {
            globalData.value = data
        }
    }
    function setGlobalDropData(data) {
        if (data) {
            globalDropData.value = data
        }
    }

    const introAnimationIsDone = useIntroAnimationIsDone()
    const introAnimationShowContent = useIntroAnimationShowContent()
    const pageIsTransitioning = usePageIsTransitioning()

    return {
        globalData,
        globalDropData,
        setGlobalDropData,
        setGlobalData,
        introAnimationIsDone,
        introAnimationShowContent,
        pageIsTransitioning,
    }
}

<script setup>
const props = defineProps({
  successHeadline: String,
  successText: String,
})
const emit = defineEmits(['close'])
</script>

<template>
  <div class="success-form flex h-full flex-col pb-bleed text-ivory">
    <ui-font-text type="h2" class="mb-spacing-04 lg:mb-spacing-04-desktop">{{
      successHeadline
    }}</ui-font-text>
    <ui-font-text type="body" class="mb-spacing-06 lg:mb-spacing-06-desktop">{{
      successText
    }}</ui-font-text>

    <button
      aria-label="Submit"
      data-cursor="empty"
      class="pointer-events-all mt-auto flex h-spacing-05-desktop w-full cursor-pointer items-center rounded-corner-02 border-stroke-dark bg-focus-light text-dark-green transition-all lg:ml-auto lg:w-[var(--cols-2)]"
      @click="emit('close')"
    >
      <ui-font-text type="label" class="mx-auto flex flex-row gap-2.5">
        <nuxt-link aria-label="close button" class="flex flex-row items-center gap-2.5">
          close
          <NuxtIcon name="cross-mouse" class="icon h-[12px] w-[12px] self-center"></NuxtIcon>
        </nuxt-link>
      </ui-font-text>
    </button>
  </div>
</template>

<style scoped>
.icon:deep(svg) {
  fill: var(--dark-green);
  width: 100%;
  height: 100%;
}
</style>

const useNavigationHistory = () => useState("navigation-history", () => []);

/**
 * used for switching over to showing a back button in the header,
 * is used for some page types, when navigated to. so not on page load
 */

export default defineNuxtRouteMiddleware((to, from) => {
    // console.log(to, from)
    const navigationHistory = useNavigationHistory();
    const router = useRouter();
    const { currentScroll } = useSmoothScroll();
    const scrollY = currentScroll.value;

    // console.log('currentScroll 1', scrollY)

    const PAGE_TYPES_USING_OVERLAY_HEADER = [
        "articles",
        "articles-slug",
        "jobs",
        "jobs-slug",
    ];

    // page load
    if (to.path === from.path) return;

    const [pageType, slug] = to.path.split("/").filter(Boolean);
    const [oldPageType, oldSlug] = from.path.split("/").filter(Boolean);

    // console.log(to.path.split('/').filter(Boolean), from.path.split('/').filter(Boolean))

    const oldPageIsOverlayPage =
        PAGE_TYPES_USING_OVERLAY_HEADER.includes(oldPageType) && oldSlug;

    const newPageIsOverlayPage =
        PAGE_TYPES_USING_OVERLAY_HEADER.includes(pageType) && slug;

    const navigatingBackBetweenOverlayPages =
        to.path === navigationHistory.value.at(-2) && oldPageIsOverlayPage;

    navigationHistory.value.push(to.path);
    // Fresh load and current page is an overlay page
    if (PAGE_TYPES_USING_OVERLAY_HEADER.includes(from.name)) {
        // console.log('currentScroll 2', scrollY)

        router.transition = { name: "overlayOut", payload: { to, scrollY } };

        // if (router.transition?.name !== "overlayOut") {
        const oldPage = document.querySelector(".overlay-page");

        if (oldPage) {
            oldPage.style.top = scrollY * -1 + "px";
            oldPage.style.position = "fixed";
            oldPage.style.width = "100vw";
            oldPage.style.left = 0;
            oldPage.style.zIndex = 100;
        }
        // }

        return;
    }

    // navigating to an overlay page
    if (newPageIsOverlayPage && !navigatingBackBetweenOverlayPages) {
        router.transition = !router.transition?.name
            ? { name: "overlayIn" }
            : router.transition;
        to.meta.overlayPage = true;
        return;
    }

    // navigating from an overlay page
    // if (from.meta.overlayPage) {
    // if (router.transition?.name) {
    //     return
    // }
    //     console.log('currentScroll 3', currentScroll.value)

    //     router.transition = { name: 'overlayOut', payload: { to, currentScroll } }

    //     return
    // }
});

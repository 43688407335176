<script setup>
const props = defineProps({
  useIcons: { type: Boolean, default: false },
});
const { createCursorFollower } = useMouseCursor();
onMounted(() => {
  if (process.client) {
    createCursorFollower();
  }
});
</script>
<template>
  <div
    class="mouse pointer-events-none fixed left-0 top-0 z-[var(--z-index-cursor)] flex h-[72px] w-[72px] items-center justify-center rounded-full bg-focus-light opacity-0 mix-blend-difference"
  >
    <div
      v-if="useIcons"
      class="icons absolute bottom-0 left-0 right-0 top-0 h-full w-full"
    >
      <NuxtIcon
        class="icon download-icon h-[10px] w-[10px]"
        name="download"
        filled
      />
      <NuxtIcon
        class="icon arrow-down-icon h-[10px] w-[10px]"
        name="arrow-down"
        filled
      />
      <NuxtIcon
        class="icon plus-icon h-[10px] w-[10px]"
        name="plus-mouse"
        filled
      />
      <NuxtIcon
        class="icon cross-icon h-[10px] w-[10px]"
        name="cross-mouse"
        filled
      />
      <NuxtIcon
        class="icon chevron-icon h-[10px] w-[10px]"
        name="chevron-right"
        filled
      />
    </div>
  </div>
</template>
